<template>
    <div class="event-list-view">
        <div class="ctrl-bar">
            <div class="label">事件日期：</div>
            <n-date-picker
                v-model:value="filters.dateRange"
                size="small"
                style="width: 230px"
                class="input"
                type="daterange"
                clearable></n-date-picker>
            <div class="label">创建人：</div>
            <n-input
                v-model:value="filters.creator"
                size="small"
                class="input"
                style="width: 120px"
                clearable></n-input>
            <div class="label">关联资源：</div>
            <n-input
                v-model:value="filters.target"
                size="small"
                class="input"
                style="width: 200px"
                clearable></n-input>
            <n-button
                size="small"
                type="primary"
                class="action"
                @click="onSearch">
                <template #icon>
                    <n-icon>
                        <search-filled />
                    </n-icon>
                </template>
                查询
            </n-button>
            <n-button size="small" class="submit" @click="onCSVExport">
                <template #icon>
                    <n-icon>
                        <TableViewFilled />
                    </n-icon>
                </template>
                导出CSV
            </n-button>
        </div>
        <div class="content">
            <n-data-table
                class="table"
                :columns="tableColumns"
                :data="tableData"
                :loading="tableLoading"></n-data-table>
            <div class="pagination">
                <n-pagination
                    v-model:page="pageIndex"
                    v-model:page-size="pageSize"
                    :page-count="pageCount"
                    show-size-picker
                    :page-sizes="[20, 50, 100]"
                    @update:page="onPageIndexChange"
                    @update:page-size="onPageSizeChange"></n-pagination>
            </div>
        </div>
        <n-modal v-model:show="isEditEventShow">
            <n-card size="small" style="width: 600px">
                <n-form
                    ref="eventEditFormRef"
                    :model="eventForm"
                    label-placement="left">
                    <n-form-item label="事件标题" path="title">
                        <n-input
                            v-model:value="eventForm.title"
                            size="small"></n-input>
                    </n-form-item>
                    <n-form-item label="发生日期" path="date">
                        <n-date-picker
                            v-model:value="eventForm.date"
                            size="small"></n-date-picker>
                    </n-form-item>
                    <n-form-item label="详情链接">
                        <n-input
                            v-model:value="eventForm.info.detail"
                            size="small"></n-input>
                    </n-form-item>
                </n-form>
                <n-alert title="说明" type="warning">
                    暂不支持在此处调整事件的关联关系，可前往CostTree成本趋势图成本事件管理调整
                </n-alert>
                <template #action>
                    <div style="text-align: right">
                        <n-button
                            type="primary"
                            size="small"
                            @click="onEditEventSubmit"
                            :loading="isEventEditSubmiting"
                            >提交</n-button
                        >
                    </div>
                </template>
            </n-card>
        </n-modal>
    </div>
</template>

<style lang="less" scoped>
@import '../../common/common.less';

.event-list-view {
    .common-content;

    .ctrl-bar {
        .common-ctrl-bar;
    }

    .content {
        margin-top: 10px;
        padding-bottom: 100px;

        .table {
            background-color: #fff;
        }

        .pagination {
            display: flex;
            flex-direction: row;
            justify-content: right;
            margin-top: 10px;
        }
    }
}
</style>

<script setup>
import { ref, h, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
    NButton,
    NInput,
    NIcon,
    NDatePicker,
    NDataTable,
    NPagination,
    NPopover,
    NModal,
    NCard,
    NForm,
    NFormItem,
    NAlert,
    useMessage,
} from 'naive-ui';
import { SearchFilled, TableViewFilled } from '@vicons/material';
import dayjs from 'dayjs';
import CtrlCell from '@/components/CtrlCell';
import exportCSV from '@/common/CSVGenerator';
import { EventAPI } from '@/common/API';
import { useUserStore } from '@/stores/global';

const store = useUserStore();

const route = useRoute();
const router = useRouter();

const message = useMessage();

let tableColumns = ref([
    {
        title: '事件',
        key: 'title',
        ellipsis: true,
        resizable: true,
    },
    {
        title: '事件日期',
        key: 'date',
        align: 'center',
        width: 100,
        render: row => dayjs(row.date * 1000).format('YYYY-MM-DD'),
    },
    {
        title: '关联资源',
        key: 'target',
        align: 'center',
        width: 80,
        render: row => {
            if (!row.target || row.target.length < 1) {
                return '-';
            }
            return (
                <NPopover
                    trigger="hover"
                    placement="bottom"
                    v-slots={{
                        default: () => {
                            return (
                                <>
                                    {row.target.map(txt => {
                                        return <div>{txt}</div>;
                                    })}
                                </>
                            );
                        },
                        trigger: () => (
                            <NButton
                                size="tiny"
                                type="primary"
                                quaternary={true}>
                                查看
                            </NButton>
                        ),
                    }}></NPopover>
            );
        },
    },
    {
        title: '详情',
        align: 'center',
        width: 80,
        render(row) {
            if (!row.info.detail) {
                return '-';
            }
            function redirect(url) {
                window.open(url, '_blank');
            }
            return (
                <NButton
                    size="tiny"
                    type="primary"
                    quaternary={true}
                    onClick={() => redirect(row.info.detail)}>
                    点击跳转
                </NButton>
            );
        },
    },

    {
        title: '创建人',
        key: 'creator',
        align: 'center',
        width: 100,
        ellipsis: {
            tooltip: true,
        },
    },
    {
        title: '创建时间',
        key: 'create_time',
        align: 'center',
        width: 160,
    },
    {
        title: '',
        align: 'center',
        width: 80,
        render(row) {
            return (
                <CtrlCell
                    row={row}
                    disableItems={
                        store.currentUser.username !== row.creator
                            ? ['edit', 'delete']
                            : []
                    }
                    deleteConfirm="确定删除该事件?"
                    onEdit={() => showEventEdit(row)}
                    onDelete={() => deleteEvent(row.id)}
                />
            );
        },
    },
]);

let tableData = ref([]);

let tableLoading = ref(false);

let pageIndex = ref(+route.query.index || 1);

let pageCount = ref(1);

let pageSize = ref(+route.query.size || 20);

let filters = ref({
    dateRange: null,
    creator: '',
    target: '',
});

function onSearch() {
    router.replace({
        query: {
            ...(filters.value.creator !== ''
                ? { creator: filters.value.creator }
                : null),
            ...(!!filters.value.dateRange
                ? {
                      start: filters.value.dateRange[0],
                      end: filters.value.dateRange[1],
                  }
                : null),
            ...(filters.value.target !== ''
                ? { target: filters.value.target }
                : null),
            index: 1,
            size: route.query.size || 20,
            t: new Date().getTime(),
        },
    });
}

function onPageIndexChange(index) {
    router.replace({ query: { ...route.query, index } });
}

function onPageSizeChange(size) {
    pageSize.value = size;
    router.replace({
        query: { ...route.query, index: 1, size, t: new Date().getTime() },
    });
}

function loadData() {
    tableLoading.value = true;
    let ft = filters.value;
    let start = ft.dateRange ? ft.dateRange[0] : undefined;
    start = start ? start / 1000 : start;
    let end = ft.dateRange ? ft.dateRange[1] : undefined;
    end = end ? end / 1000 : end;
    let creator = ft.creator === '' ? undefined : ft.creator;
    let target = ft.target === '' ? undefined : ft.target;

    EventAPI.getList(
        start,
        end,
        pageIndex.value,
        pageSize.value,
        target,
        creator
    ).then(res => {
        tableLoading.value = false;
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        tableData.value = res.data.list;
        pageCount.value =
            Math.floor(res.data.total / pageSize.value) +
            (res.data.total % pageSize.value !== 0 ? 1 : 0);
    });
}

function deleteEvent(id) {
    EventAPI.deleteEvent(id).then(res => {
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        loadData();
    });
}

// edit event modal
let isEditEventShow = ref(false);

function showEventEdit(info) {
    isEditEventShow.value = true;
    eventForm.value = { ...info };
    eventForm.value.date = eventForm.value.date * 1000;
}

function genFormData() {
    return {
        title: '',
        date: null,
        target: [],
        info: {
            detail: '',
        },
    };
}

let eventForm = ref(genFormData());

let isEventEditSubmiting = ref(false);

function onEditEventSubmit() {
    isEventEditSubmiting.value = true;
    let payload = { ...eventForm.value };
    payload.date = Math.floor(payload.date / 1000);
    EventAPI.updateEvent(payload.id, payload).then(res => {
        isEventEditSubmiting.value = false;
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        message.success('操作完成');
        isEditEventShow.value = false;
        loadData();
    });
}

function onCSVExport() {
    let cols = [
        {
            label: '事件',
            key: 'title',
        },
        {
            label: '事件日期',
            key: 'date',
        },
        {
            label: '关联资源',
            key: 'target',
        },
        {
            label: '详情',
            key: 'detail',
        },
        {
            label: '创建人',
            key: 'creator',
        },
        {
            label: '创建时间',
            key: 'create_time',
        },
    ];
    let exportData = [];
    tableData.value.map(info => {
        exportData.push({
            ...info,
            date: dayjs(info.date * 1000).format('YYYY-MM-DD'),
            target: info.target.join('||'),
            detail: info.info.detail,
        });
    });
    exportCSV(exportData, cols);
}

loadData();

watch(route, val => {
    if (val.name !== 'event_list') {
        return;
    }
    pageIndex.value = +route.query.index || 1;
    pageSize.value = +route.query.size || 20;
    filters.value.dateRange =
        route.query.start && route.query.end
            ? [+route.query.start, +route.query.end]
            : null;
    filters.value.creator = route.query.creator || '';
    filters.value.target = route.query.target || '';
    loadData();
});
</script>
